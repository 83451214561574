<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        เพิ่มใบสั่งซื้อสินค้า
      </v-toolbar-title>
    </v-toolbar>
    <div class="mx-4 box box-2" style="min-height: 100%;">
      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">
        <v-col
            style="height: 100%"
        >
          <v-card
              style="height: 100%;overflow: auto"
              class="pa-4 rounded-lg elevation-2"
              outlined
              tile
          >
            <v-col
                cols="12"
                md="12"
                lg="10"
                xl="8"
                style="height: 100%"
            >

              <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
              >
                <v-row>
                  <v-col cols="4" align-self="center">วันที่รับของ</v-col>
                  <v-col cols="8">
                    <DatePicker v-model="receive_date"
                                placeholder="เลือกวันที่"
                                hide-details="auto"
                                default-today
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" align-self="center">วิธีรับของ</v-col>
                  <v-col cols="8">

                    <v-select
                        outlined
                        dense
                        height="32px"
                        class="rounded mt-3"
                        v-model="receive_type"
                        :items="receive_type_select"
                        hide-details="auto"
                        placeholder="เลือกวิธีรับสินค้า"
                        :rules="[rules.required_select]"
                    ></v-select>
                    <!--                <v-text-field :disabled="!editable" outlined height="32px" hide-details/>-->
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" align-self="center">สถานที่</v-col>
                  <v-col cols="8">
                    <!--                  <v-text-field :rules="[rules.required]"-->
                    <!--                                outlined height="32px"-->
                    <!--                                hide-details="auto"-->
                    <!--                                placeholder="เลือกสถานที่"-->
                    <!--                  />-->
                    <v-select v-model="supplier"
                              :items="supplierSelect"
                              outlined
                              dense
                              height="32px"
                              hide-details="auto"
                              placeholder="เลือกสถานที่สั่ง"
                              :rules="[rules.required_select]"
                              item-text="name"
                              item-value="id"
                    ></v-select>
                  </v-col>
                </v-row>


                <!--            <v-row>-->
                <!--              <v-col cols="3" align-self="center">เลือกสินค้าที่จะสั่ง</v-col>-->
                <!--            </v-row>-->
                <v-row class="mt-4">
                  <v-col align-self="center">
                    <span class="mr-2">เลือกสินค้าที่จะสั่ง</span>
                    <v-icon @click="addProduct" color="success">mdi-plus-circle</v-icon>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="text-right">
                    <v-data-table
                        :headers="productSelectHeader"
                        :items="products"
                        disable-sort
                        hide-default-footer
                    >
                      <template #item.no="{item}">
                        <td>
                          {{ products.indexOf(item) + 1 }}
                        </td>
                      </template>
                      <template #item.product_id="{ item }">
                        <v-select
                            outlined
                            dense
                            height="32px"
                            class="rounded"
                            v-model="item.product_id"
                            :items="productsSelect"
                            :rules="[rules.required_select]"
                            hide-details="auto"
                            placeholder="เลือกสินค้า"
                            item-value="product_id"
                        >
                          <template slot="selection" slot-scope="data">
                            <span>{{ data.item.name }}</span>
                          </template>
                          <template slot="item" slot-scope="data">
                            <span>{{ data.item.name }}</span>
                          </template>
                        </v-select>
                      </template>
                      <!--                    <template #item.quantity="{ item }">-->
                      <!--                      <v-text-field v-model="item.quantity"-->
                      <!--                                    type="number"-->
                      <!--                                    :rules="[rules.required,rules.min]"-->
                      <!--                                    outlined-->
                      <!--                                    height="32px"-->
                      <!--                                    :suffix="item.productObj !== null ? item.productObj.unit:''"-->
                      <!--                                    hide-details="auto"/>-->
                      <!--                    </template>-->
                      <template #item.round="{ item }">
                        <v-text-field v-model="item.round"
                                      type="number"
                                      :rules="[rules.required,rules.min]"
                                      outlined
                                      height="32px"
                                      suffix="เที่ยว"
                                      hide-details="auto"/>
                      </template>
                      <!--                      <template #item.price="{ item }">-->
                      <!--                        <v-text-field v-model="item.price"-->
                      <!--                                      type="number"-->
                      <!--                                      :rules="[rules.required]"-->
                      <!--                                      outlined-->
                      <!--                                      height="32px"-->
                      <!--                                      suffix="บาท"-->
                      <!--                                      hide-details="auto"/>-->
                      <!--                      </template>-->

                      <template #item.driver_id="{ item }">
                        <v-select
                            outlined
                            dense
                            height="32px"
                            class="rounded"
                            v-model="item.driver_id"
                            :items="driverSelect"
                            item-text="first_name"
                            item-value="id"
                            :rules="[rules.required_select]"
                            hide-details="auto"
                            placeholder="เลือกคนขับรถ"
                        >
                          <template slot="item" slot-scope="data">
                            {{ data.item.first_name }} {{ data.item.last_name }}
                          </template>
                        </v-select>
                      </template>

                      <template #item.vehicle_id="{ item }">
                        <v-select
                            outlined
                            dense
                            height="32px"
                            class="rounded"
                            v-model="item.vehicle_id"
                            :items="vehicleSelect"
                            item-text="name"
                            item-value="id"
                            :rules="[rules.required_select]"
                            hide-details="auto"
                            placeholder="เลือกรถที่ไปรับของ"
                        >
                          <template slot="selection" slot-scope="data">
                            <div style="display: flex;width: 100%;justify-content: space-between">
                              <span>{{ data.item.name }}</span>
                              <span><span style="color: #265a88">ทะเบียน {{ data.item.licence }}</span></span>
                            </div>
                          </template>
                          <template slot="item" slot-scope="data">
                            <div style="display: flex;width: 100%;justify-content: space-between">
                              <span>{{ data.item.name }}</span>
                              <span><span style="color: #265a88">ทะเบียน {{ data.item.licence }}</span></span>
                            </div>
                          </template>
                        </v-select>
                      </template>

                      <template #item.index="{ item }">
                        <v-icon @click="removeProduct(item)" color="error">mdi-close-circle</v-icon>
                      </template>

                      <!--                      <template slot="body.append">-->
                      <!--                        <tr class="primary&#45;&#45;text">-->
                      <!--                          <td class="font-size-5 font-bold text-left">รวม</td>-->
                      <!--                          <td class="font-size-5 font-bold"></td>-->
                      <!--                          <td v-if="receive_type==='ออกไปรับ'" class="font-size-5 font-bold"></td>-->
                      <!--                          <td v-if="receive_type==='ออกไปรับ'" class="font-size-5 font-bold"></td>-->
                      <!--                          <td class="text-center font-size-5 font-bold">{{ sumField() }} บาท</td>-->
                      <!--                        </tr>-->
                      <!--                      </template>-->


                    </v-data-table>
                  </v-col>
                </v-row>

                <!--              <div v-if="receive_type==='ออกไปรับ'">-->
                <!--                <v-row class="mt-4">-->
                <!--                  <v-col align-self="center">-->
                <!--                    <span class="mr-2">เที่ยวรถ</span>-->
                <!--                    <v-icon @click="addTrip" color="success">mdi-plus-circle</v-icon>-->
                <!--                  </v-col>-->
                <!--                </v-row>-->

                <!--                <v-row>-->
                <!--                  <v-col cols="8" class="text-right">-->
                <!--                    <v-data-table-->
                <!--                        :headers="tripHeader"-->
                <!--                        :items="trips"-->
                <!--                        disable-sort-->
                <!--                        hide-default-footer-->
                <!--                    >-->
                <!--                      <template #item.no="{item}">-->
                <!--                        <td>-->
                <!--                          {{ trips.indexOf(item) + 1 }}-->
                <!--                        </td>-->
                <!--                      </template>-->
                <!--                      <template #item.driver_id="{ item }">-->
                <!--                        <v-select-->
                <!--                            outlined-->
                <!--                            dense-->
                <!--                            height="32px"-->
                <!--                            class="rounded"-->
                <!--                            v-model="item.driver_id"-->
                <!--                            :items="driverSelect"-->
                <!--                            item-text="first_name"-->
                <!--                            item-value="id"-->
                <!--                            :rules="[rules.required_select]"-->
                <!--                            hide-details="auto"-->
                <!--                            placeholder="เลือกคนขับรถ"-->
                <!--                        >-->
                <!--                          <template slot="item" slot-scope="data">-->
                <!--                            {{ data.item.first_name }} {{ data.item.last_name }}-->
                <!--                          </template>-->
                <!--                        </v-select>-->
                <!--                      </template>-->

                <!--                      <template #item.vehicle_id="{ item }">-->
                <!--                        <v-select-->
                <!--                            outlined-->
                <!--                            dense-->
                <!--                            height="32px"-->
                <!--                            class="rounded"-->
                <!--                            v-model="item.vehicle_id"-->
                <!--                            :items="vehicleSelect"-->
                <!--                            item-text="name"-->
                <!--                            item-value="id"-->
                <!--                            :rules="[rules.required_select]"-->
                <!--                            hide-details="auto"-->
                <!--                            placeholder="เลือกรถที่ไปรับของ"-->
                <!--                        >-->
                <!--                          <template slot="selection" slot-scope="data">-->
                <!--                            &lt;!&ndash; HTML that describe how select should render selected items &ndash;&gt;-->
                <!--                            <div style="display: flex;width: 100%;justify-content: space-between">-->
                <!--                              <span>{{ data.item.name }}</span>-->
                <!--                              <span><span style="color: #265a88">ทะเบียน {{ data.item.licence }}</span></span>-->
                <!--                            </div>-->
                <!--                          </template>-->
                <!--                          <template slot="item" slot-scope="data">-->
                <!--                            <div style="display: flex;width: 100%;justify-content: space-between">-->
                <!--                              <span>{{ data.item.name }}</span>-->
                <!--                              <span><span style="color: #265a88">ทะเบียน {{ data.item.licence }}</span></span>-->
                <!--                            </div>-->
                <!--                          </template>-->
                <!--                        </v-select>-->
                <!--                      </template>-->

                <!--                      <template #item.index="{ item }">-->
                <!--                        <v-icon @click="removeTrip(item)" color="error">mdi-close-circle</v-icon>-->
                <!--                      </template>-->


                <!--                    </v-data-table>-->
                <!--                  </v-col>-->
                <!--                </v-row>-->
                <!--              </div>-->


                <v-row>
                  <v-btn :loading="isLoading" @click="validate" class="ml-2" color="success">เพิ่มใบสั่งสินค้า</v-btn>
                </v-row>

                <v-snackbar
                    :dark="false"
                    v-model="snackbar"
                    timeout="2000"
                    top
                    right
                    color="success"
                >
                  {{ snackbarText }}
                </v-snackbar>
              </v-form>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import DatePicker from "@/components/DatePicker";

export default {
  name: "CreatePO",
  components: {DatePicker},
  data() {
    return {
      snackbar: false,
      snackbarText: '',
      productSelectHeader: [
        {
          text: 'ลำดับที่',
          value: 'no',
          align: 'left',
        },
        {
          text: 'สินค้า',
          value: 'product_id',
          align: 'left',
        },
        // {
        //   text: 'ราคา',
        //   value: 'price',
        //   align: 'center',
        //   width: 186
        // },
        {
          text: '',
          value: 'index',
          align: 'center',
          width: 30
        },
      ],
      // tripHeader: [
      //   {
      //     text: 'ลำดับที่',
      //     value: 'no',
      //     align: 'left',
      //   },
      //   {
      //     text: 'คนขับรถ',
      //     value: 'driver_id',
      //     align: 'center',
      //     // width: 268
      //   },
      //   {
      //     text: 'รถ',
      //     value: 'vehicle_id',
      //     align: 'center',
      //     width: 268
      //   },
      //   {
      //     text: '',
      //     value: 'index',
      //     align: 'center',
      //     width: 30
      //   },
      // ],
      order_date: '',
      receive_date: '',
      receive_type: '',
      supplier: '',
      supplierSelect: [],
      trips: [
        {
          driver_id: null,
          vehicle_id: null
        },
      ],
      driverSelect: [],
      vehicleSelect: [],
      products: [{
        product_id: 0,
        price: 0,
        vehicle_id: null,
        driver_id: null,
        round: 1
        // productObj: null
      }],
      productsSelect: [],
      receive_type_select: ['ออกไปรับ', 'มาส่ง'],
      rules: {
        required: value => !!value || "ต้องกรอก",
        required_select: value => !!value || "ต้องเลือก",
        min: value => value > 0 || "จำนวนต้องเป็นตั้งแต่ 1 ขึ้นไป"
      },
      valid: true,
      isLoading: false
    }
  },
  created() {
    this.$store.state.isLoading = false
    this.getProductsSelect()
    this.getSupplierSelect()
    this.getDriver()
    this.getVehicle()
  },
  methods: {
    sumField() {
      if (this.receive_type === 'ออกไปรับ') {
        return this.products.reduce((a, b) => a + (parseFloat(b['price']) || 0), 0)
      } else {
        return this.products.reduce((a, b) => a + (parseFloat(b['price']) || 0), 0)
      }
    },
    getProductsSelect() {
      axios.get('/api/stocks/product/')
          .then(res => {
            let productList = []
            res.data.data.forEach(p => {
              productList.push({
                product_id: p.id,
                unit: p.unit,
                name: p.name
              })
            })
            this.productsSelect = productList
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
          .finally()
    },
    getSupplierSelect() {
      axios.get('/api/stocks/supplier/')
          .then(res => {
            this.supplierSelect = res.data.data
          })
          .catch(e => this.$store.state.snackbar = {
            text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
            visible: true,
            color: 'error'
          })
    },
    getDriver() {
      axios.get('/api/stocks/driver/')
          .then(res => {
            this.driverSelect = res.data.data
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    getVehicle() {
      axios.get('/api/stocks/vehicle/รถบรรทุก/')
          .then(res => {
            this.vehicleSelect = res.data.data
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    addProduct() {
      this.products.push({
        product_id: 0,
        price: 0,
        // productObj: null,
        vehicle_id: null,
        driver_id: null,
        round: 1
      })
    },
    removeProduct(index) {
      if (this.products.length > 1)
        this.products.splice(this.products.indexOf(index), 1)
      // console.log(this.products.indexOf(index), index)
    },
    // removeTrip(index) {
    //   if (this.trips.length > 1)
    //     this.trips.splice(this.trips.indexOf(index), 1)
    // },
    async validate() {
      await this.$refs.form.validate()
      if (this.valid) {
        this.addToDatabase()
      }
    },
    addToDatabase() {
      this.isLoading = true
      this.products.map(p => {
        p.price = parseFloat(p.price)
      })
      if (this.receive_type === 'มาส่ง') {
        this.products.map(p => {
          p.driver_id = null
          p.vehicle_id = null
          p.round = null
        })
      }
      let payload = {
        po_item: this.products,
        receive_date: this.receive_date,
        receive_type: this.receive_type,
        supplier_id: this.supplier,
      }

      console.log(payload)
      axios.post('/api/stocks/po/', payload)
          .then(() => {
            this.$store.state.snackbar = {
              text: `เพิ่ม PO สำเร็จ`,
              visible: true,
              color: 'success'
            }
            this.isLoading = false
            this.$router.push({path: '/stock/po/'})
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    }
  },
  watch: {
    receive_type(value) {
      if (value === "มาส่ง") {
        this.productSelectHeader = this.productSelectHeader = [
          {
            text: 'ลำดับที่',
            value: 'no',
            align: 'left',
          },
          {
            text: 'สินค้า',
            value: 'product_id',
            align: 'left',
          },
          // {
          //   text: 'ราคา',
          //   value: 'price',
          //   align: 'center',
          //   width: 186
          // },
          {
            text: '',
            value: 'index',
            align: 'center',
            width: 30
          },
        ]
      } else if (value === 'ออกไปรับ') {
        this.productSelectHeader = this.productSelectHeader = [
          {
            text: 'เที่ยวที่',
            value: 'no',
            align: 'left',
          },
          {
            text: 'เที่ยว',
            value: 'round',
            align: 'center',
            width: 186
          },
          {
            text: 'สินค้าที่บรรทุก',
            value: 'product_id',
            align: 'left',
            width: 256
          },
          // {
          //   text: 'ราคา',
          //   value: 'price',
          //   align: 'center',
          //   width: 186
          // },
          {
            text: 'คนขับรถ',
            value: 'driver_id',
            align: 'center',
            width: 216
          },
          {
            text: 'รถ',
            value: 'vehicle_id',
            align: 'center',
            width: 256
          },
          {
            text: '',
            value: 'index',
            align: 'center',
            width: 30
          },
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>